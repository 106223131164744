<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Transport Report
            <v-spacer></v-spacer>
            <v-menu
              v-if="transportForm.items.data.length > 0"
              class="download_drop"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  :color="'success'"
                  class="white--text"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                >
                  <v-icon left small>cloud_download</v-icon>
                  &nbsp;DOWNLOAD TRANSPORT REPORT
                </v-btn>
              </template>

              <v-list dense>
                <v-subheader>COLUMNS</v-subheader>

                <v-list-item
                  v-for="(column, key) in downloadColumns"
                  :key="key"
                  link
                >
                  <v-checkbox
                    v-model="column.selected"
                    :label="column.name"
                    color="success"
                    hide-details
                  ></v-checkbox>
                </v-list-item>
                <v-subheader>ACTIONS</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item
                    v-if="gradeId !== 'all'"
                    @click="download('pdf')"
                    link
                  >
                    <v-list-item-title
                      ><v-icon color="red" small left>picture_as_pdf</v-icon>
                      &nbsp;PDF</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="download('excel')">
                    <v-list-item-title
                      ><v-icon color="success" small left>border_all</v-icon
                      >&nbsp;&nbsp; Download EXCEL</v-list-item-title
                    >
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card>
            <v-card-title outlined>
              <v-flex>
                <v-grade-field
                  v-model="gradeId"
                  :inside="true"
                  @selectedGrade="gradesSection"
                  :with-all="true"
                ></v-grade-field>
              </v-flex>
              <v-flex>
                <v-section-field
                  :sections="sections"
                  v-model="sectionId"
                ></v-section-field>
              </v-flex>
              <v-flex>
                <search-button
                  :disabled="!gradeId"
                  permission="subject-read"
                  @action="get()"
                  >Search</search-button
                >
              </v-flex>
            </v-card-title>
          </v-card>
          <br />
          <v-data-table
            :headers="headers"
            :hide-default-footer="transportForm.items.data.length < 1"
            :items="transportForm.items.data"
            :footer-props="footerProps"
            sort-by="roll"
            :options.sync="pagination"
            :server-items-length="transportForm.items.meta.total"
            :loading="transportForm.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-center">
                  <strong>{{ item.roll }}</strong>
                </td>
                <td class="text-xs-left">{{ item.route || "-" }}</td>
                <td class="text-xs-left">{{ item.destination || "-" }}</td>
                <td class="text-xs-left">
                  <strong> {{ item.rate ? "NRS " + item.rate : "-" }}</strong>
                  <br />
                  <small v-if="item.rate && item.discount"
                    >Discount {{ item.discount }}% i.e NRS
                    <strong>{{
                      Math.round(item.rate * (item.discount / 100))
                    }}</strong>
                  </small>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { passDataToExternalDomain } from "../../../../library/helpers";

export default {
  mixins: [Mixins],
  components: {
    // GradeSectionFilter,
  },
  data: () => ({
    // btns: [["Removed", "0"]],
    // colors: ["deep-purple accent-4", "error", "teal darken-1"],
    // items: [...Array(4)].map((_, i) => `Item ${i}`),
    downloadColumns: [
      { value: "roll", name: "Roll No.", selected: true },
      { value: "grade", name: "Grade", selected: false },
      { value: "section", name: "Section", selected: false },
      { value: "name", name: "Student Name", selected: true },
      { value: "route", name: "Route", selected: true },
      { value: "destination", name: "Destination", selected: true },
      { value: "driver", name: "Driver Name", selected: false },
      { value: "vehicle", name: "Vehicle Name", selected: false },
      { value: "vehicle_no", name: "Vehicle No.", selected: false },
      { value: "rate", name: "Rate", selected: true },
      { value: "discount", name: "Discount", selected: true },
    ],
    // migrateDialog: false,
    transportForm: new Form(
      {
        route_id: "",
        enroll_id: "",
        discount: "",
        fare_id: "",
      },
      "/api/transport/report"
    ),
    // routes: [],
    // fare: "",
    // url: "",
    // destinations: [],
    // oldBatch: {},
    headers: [
      { text: "#", align: "left", value: "sno", sortable: false, width: 20 },
      {
        text: "Name",
        align: "left",
        value: "name",
        sortable: false,
        width: 180,
      },
      { text: "Roll No.", align: "left", value: "roll", sortable: false },
      { text: "Route", align: "left", value: "type", sortable: false },
      { text: "Destination", align: "left", value: "seat", sortable: false },
      {
        text: "Fee",
        align: "left",
        value: "driver",
        sortable: false,
        width: 140,
      },
    ],
    gradeId: "",
    sectionId: "",
    sections: [],
    pagination: {
      itemsPerPage: 25,
    },
    // rowsPerPageItems: [5, 10, 25, 50, 75],
    // drivers: [],
    // search: "",
    // studentWithTransporations: [],
    download_url: "",
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    gradeId: function() {
      this.sectionId = "";
      this.transportForm.items.data = [];
      //   this.get();
    },
    sectionId: function() {
      //   this.get();
    },
  },
  computed: {
    // ...mapState(["grade", "batch", "batches"]),
    // selectedGrade() {
    //   let output = {};
    //   this.grade.grade.items.map((item) => {
    //     if (item.id === this.grade.grade.selected) {
    //       output = item;
    //     }
    //   });
    //   return output;
    // },
  },
  mounted() {},
  methods: {
    gradesSection(grade) {
      this.sections = grade.sections;
    },

    get() {
      if (!this.gradeId) {
        return;
      }
      let query = this.queryString(
        `gradeId=${this.gradeId}&sectionId=${this.sectionId}`
      );
      this.transportForm
        .get(null, query)
        .then((res) => {
          this.download_url = res.data.download_url;
        })
        .catch((err) => {});
    },

    // getDrivers() {
    //   this.$rest.get("/api/member?type=driver").then(({ data }) => {
    //     this.drivers = data.data.map((driver) => {
    //       return { text: driver.name, value: driver.id };
    //     });
    //   });
    // },
    // getRoutes() {
    //   this.$rest.get("/api/route?rowsPerPage=25").then(({ data }) => {
    //     let $this = this;
    //     this.routes = data.data.map((route) => {
    //       return { text: route.name, value: route.id };
    //     });
    //   });
    // },
    // getDestinations() {
    //   this.$rest
    //     .get(
    //       "/api/route-fare?rowsPerPage=25&routeId=" +
    //         this.transportForm.route_id
    //     )
    //     .then((res) => {
    //       this.destinations = res.data.map(function(des) {
    //         return {
    //           text: des.destination,
    //           value: des.fare_id,
    //           rate: des.rate,
    //         };
    //       });
    //     });
    // },

    download(type) {
      let columns = [];
      this.downloadColumns.map((item) => {
        if (item.selected === true) columns.push(item.value);
      });

      if (columns.length > 0) {
        passDataToExternalDomain("reporting");
        setTimeout(() => {
          window.open(
            this.download_url + `&type=${type}&column=${columns.join(",")}`
          );
        }, 500);
      }
    },
  },
};
</script>
<style>
.rate {
  position: absolute;
  margin-top: -20px;
  margin-left: 10px;
  color: gray;
  font-size: 12px;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px !important;
}
.v-menu__content .v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
